<template>
	<div class="container">
		<div class="top"><img src="@/assets/images/logo.png"/></div>
		<div class="main flex">
			<div class="content">
				<div class="tab">
					<ul class="flex_a">
						<li @click="handleChangeTab(1)" :class="{'checked' : tabIndex == 1}">登录</li>
						<li @click="handleChangeTab(2)" :class="{'checked' : tabIndex == 2}">注册</li>
					</ul>
				</div>
				<div class="wapper">
					<div class="item flex_a"><span class="flex_a"><img src="@/assets/images/zhanghao.png"/></span><el-input placeholder="请输入手机号" v-model="username"></el-input></div>
<!--					<div class="item flex_a" v-if="tabIndex == 2">-->
<!--						<span class="flex_a"><img src="@/assets/images/yanzhengma.png"/></span>-->
<!--						<el-input v-model="code" placeholder="短信验证码"></el-input>-->
<!--						<label v-if="timeShow" @click="getCode">获取</label>-->
<!--						<label v-else>{{times}}s</label>-->
<!--					</div>-->
					<div class="item flex_a"><span class="flex_a"><img src="@/assets/images/mima.png"/></span><el-input type="password" v-model="password" placeholder="登录密码"></el-input></div>
<!--					<div class="findpwd" v-if="tabIndex == 1" @click="toFindPwd">忘记密码</div>-->
					<el-button type="primary" @click="login">{{tabIndex == 1 ? '立即登录' : '注册并登录'}}</el-button>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				tabIndex: 1,
				username: '',
				code: '',
				password: '',
				times: '',
				timer: '',
				timeShow: true,
			}
		},
		mounted() {
			if(this.$route.query.type){
				this.tabIndex = this.$route.query.type
			}

		},
		methods: {
			handleChangeTab(index) {
				this.tabIndex = index
				this.username = ''
				this.password = ''
				this.timeShow = true
			},
			login() {
				if(!this.username.trim()){
					this.$message.error('请输入手机号');
					return
				}
				let myreg=/^[1][3,4,5,6.7,8,9][0-9]{9}$/;
				if (!myreg.test(this.username)) {
					this.$message.error('手机号格式不正确');
					return
				} 
				// if(!this.code.trim() && this.tabIndex == 2){
				// 	this.$message.error('请输入短信验证码');
				// 	return
				// }
				if(!this.password.trim()){
					this.$message.error('请输入密码');
					return
				}
				let formData = new FormData()
					formData.append('account', this.username)
					formData.append('code', this.code)
					formData.append('pwd', this.password)
				if(this.tabIndex == 1){
					this.$http({
						url: `/usersApi/editUsersLogin`,
						method: "POST",
						data: formData,
					}).then((res) => {
						if(res.state == 10200){
							this.$message({
								type: 'success',
								message: '登录成功!',
							});
							localStorage.setItem('userInfo',JSON.stringify(res.data))
							setTimeout(() => {
								this.$router.push('/')
							}, 1000);
						}
						
					});
				}else{
          let reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
          if (!reg.test(this.password)) {
            this.$message.error('密码最少8位，至少需要包含字母，数字，字符');
            return
          }
          this.$http({
						url: `/usersApi/editUsersRegister`,
						method: "POST",
						data: formData,
					}).then((res) => {
						if(res.state == 10200){
							this.$message({
								type: 'success',
								message: '注册并登录成功!',
							});
							localStorage.setItem('userInfo',JSON.stringify(res.data))
							setTimeout(() => {
								this.$router.push('/')
							}, 1000);
						}
						
					});
				}
			},
			toFindPwd() {
				this.$router.push('/findPwd')
			},

			getCode() {
				if(!this.username.trim()){
					this.$message.error('请输入手机号');
					return
				}
				let myreg=/^[1][3,4,5,6.7,8,9][0-9]{9}$/;
				if (!myreg.test(this.username)) {
					this.$message.error('手机号格式不正确');
					return
				} 
				let formData = new FormData()
					formData.append('tel', this.username)
					this.$http({
						url: `/baseApi/sendCode`,
						method: "POST",
						data: formData,
					}).then((res) => {
						if(res.state == 10200){
							this.$message.success('发送成功');
							this.times = 60 
							this.timeShow = false
							this.timer = setInterval(()=>{
								this.times--
								if(this.times===0){
									this.timeShow = true
									clearInterval(this.timer)
								}
							},1000)
						}
						
					});
				
			}
		}
	}
</script>

<style lang="scss" scoped>
.container{
	.top{
		height: 145px;
		width: 100%;
		background: url('../../assets/images/daohang.png') no-repeat;
		background-size: cover;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.main{
		height: calc(100vh - 145px);
		background: url('../../assets/images/dengluzhucebj.png') no-repeat;
		background-size: cover;
		width: 100%;
		justify-content: center;
		padding-top: 150px;
		.content{
			
			width: 300px;
			.tab{
				width: 300px;
				padding: 0 30px;
				ul{
					li{
						color: #FFF;
						font-size: 24px;
						text-align: center;
						flex: 1;
						position: relative;
						cursor: pointer;
					}
					.checked{
						&::after{
							position: absolute;
							content: '';
							left: 50%;
							bottom: -20px;
							width: 20px;
							margin-left: -10px;
							height: 2px;
							border-radius: 10px;
							background: #FFF;
						}
					}
				}
			}
			.wapper{
				height: 320px;
				width: 300px;
				padding: 40px 30px;
				background: #FFF;
				margin-top: 40px;
				border-radius: 5px;
				position: relative;
				.item{
					margin-bottom: 20px;
					position: relative;
					>span{
						width: 40px;
						height: 40px;
						background: #DCDCDC;
						justify-content: center;
						border-radius: 5px 0 0 5px;
					}
					::v-deep .el-input__inner{
						border-radius: 0;
						border-radius: 0px 5px 5px 0px;
					}
					>label{
						position: absolute;
						right: 10px;
						color: #7D2626;
						height: 40px;
						line-height: 40px;
						cursor: pointer;
					}
				}
				.findpwd{
					text-align: right;
					color: #7D2626;
					cursor: pointer;
					position: absolute;
					right: 30px;
					bottom: 150px;
				}
				.el-button{
					width: 100%;
					margin-top: 20px;
				}
			}
		}
	}
}
	
</style>
